* {
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
}

.animol-center-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../images/animol-logo-transparent-500x500.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
}